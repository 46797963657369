import type { HotelRoom } from './HotelRooms';
import { safeFormatISODate } from '../../util/datetime-fns';
import type { HotelAutocompleteOption } from './HotelSearchForm';

const baseUrl = 'https://www.finn.no/reise/hotell/wl/#/hotels';

export const createSubmitUrl = (resultUrl, destination) => {
    if (destination?.themePath) {
        return `${resultUrl}/${destination.themePath}/`;
    }
    if (destination?.type === 'hotel') {
        return `${resultUrl}/detaljer/${destination?.key}/`;
    }
    return `${resultUrl}/resultat/${destination?.key}/${isNumericString(destination?.key) ? fakeNamedDestinationPathFromText(destination.text) : ''}`;
};

const isNumericString = (value: string): boolean => {
    return /^\d+$/.test(value);
};

// this is to show a destination name in the url, but it is not actually used for anything...
const fakeNamedDestinationPathFromText = (text: string): string => {
    const destination = text
        .split(',')[0]
        .toLowerCase()
        .replace(/æ/g, 'ae')
        .replace(/ø/g, 'o')
        .replace(/å/g, 'a')
        .replace(/[^\w\-]+/g, '_');
    return `${destination}/`;
};

export const createWhitelabelUrl = (
    destinationContext: HotelAutocompleteOption | undefined,
    startDate: Date | undefined,
    endDate: Date | undefined,
    rooms: HotelRoom[] | undefined,
) => {
    if (!rooms || !destinationContext) {
        // TODO how to handle this properly
        return '';
    }

    // Place id is not returned for hotels, but using 1 as place id works
    const key = destinationContext.type === 'hotel' ? `p1-h${destinationContext.key}` : `p${destinationContext.key}`;
    const place = destinationContext.hierarchy.join(',');
    const checkIn = safeFormatISODate(startDate);
    const checkOut = safeFormatISODate(endDate);

    const numAdults = rooms.reduce((acc, cur) => acc + cur.adults, 0);
    const numChildren = rooms.reduce((acc, cur) => acc + cur.children.length, 0);
    const childrenAges = rooms.flatMap((room) => room.children).join('-');
    const numRooms = rooms.length;

    const adultsText = `${numAdults}adults`;
    const childrenText = numChildren === 0 ? null : `${numAdults}children-${childrenAges}`;
    const roomsText = `${numRooms}rooms`;
    const countsText = [adultsText, childrenText, roomsText].filter((text) => !!text).join('/');

    return `${baseUrl}/${place}-${key}/${checkIn}/${checkOut}/${countsText}`;
};
