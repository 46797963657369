import { useRef } from 'react';
import { Button } from '@fabric-ds/react';

import { useBreakpoints } from '../../hooks/useBreakpoints';

interface CloseButtonProps {
    onClick: () => void;
    setOpen: (open: boolean) => void;
    text: string;
}

export const CloseButton = ({ onClick, setOpen, text }: CloseButtonProps) => {
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const isSmallScreen = useBreakpoints({ breakpoint: 'sm' });

    const handleCloseClick = () => {
        onClick();
        setOpen(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleCloseClick();
        }
    };

    return (
        <Button
            primary
            small={!isSmallScreen}
            className={isSmallScreen ? 'w-full max-w-full' : 'w-auto max-w-auto'}
            ref={closeButtonRef}
            aria-label="bruk valgt dato"
            onClick={handleCloseClick}
            onKeyDown={handleKeyDown}>
            {text}
        </Button>
    );
};
