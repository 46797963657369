import { useEffect, useReducer, useState, type ReactNode } from 'react';
import getConfig from 'next/config';
import { Button, Tab, TabPanel, Tabs, TextField } from '@fabric-ds/react';
import { DatePicker, DatePickerCalendar, DatePickerInput, DatePickerPopover, DateRangePicker } from '@finn-no/legacy-fabric-datepicker';

import { t } from '../../locale/texts';
import { isMobile } from '../../util/mobile';
import { isNotWithinOneYearOfToday } from '../../util/datetime-fns';
import { getOneYearFromToday } from '../../util/calendar';

import { getLastSearchFlight, storeLastSearchFlight } from '../common/lastSearch';
import { flightHotelUrl, flightResultUrl as createResultUrl } from '../common/resultUrl';
import { trackSearchClick } from '../common/tracking';
import { validateSearchForm } from './validation';
import actions, { reducer } from './flightActions';
import { flightDefaultState } from './flightDefaultState';
import { AutocompleteSwap } from './AutocompleteSwap';
import { PassengersAndCabin } from './PassengersAndCabin';

import { MetaDatePickerWrapper } from '../../components/metaDatePickerWrapper/MetaDatePickerWrapper';
import { FrontpageDatePickerWrapper } from '../../components/frontpageDatePickerWrapper/FrontpageDatePickerWrapper';

type FlightSearchProps = {
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    prefill?: Record<string, any>;
    widget?: string;
    position: string;
    unleash?: Record<string, boolean>;
};

const {
    publicRuntimeConfig: { flightAutocompleteUrl, flightResultUrl },
} = getConfig();

export const FlightSearch = ({ prefill, widget, position, unleash }: FlightSearchProps) => {
    const [pristine, setPristine] = useState(true);
    const [form, dispatch] = useReducer(reducer, flightDefaultState);

    const numberOfMonths = isMobile() ? 1 : 2;
    const today = new Date();
    const enableHotelSearch = !widget;

    useEffect(() => {
        dispatch(actions.allFieldsChanged({ ...getLastSearchFlight(), ...prefill }));
    }, []);

    const setTripType = (value) => {
        setPristine(value !== form.tripType);
        dispatch(actions.setTripType(value));
    };

    const handleSubmit = (): void => {
        setPristine(false);

        if (validateSearchForm(form)) {
            storeLastSearchFlight(form);

            const item = {
                id: 'finntravel',
                type: 'Travel',
                // Due to legacy definitions in Pulse, `destination` is used instead of `arrivalCountry`
                destination: form.destinationCountry,
                arrivalCity: form.destinationCity,
                arrivalAirport: form.destination,
                departureCountry: form.originCountry,
                departureCity: form.originCity,
                departureAirport: form.origin,
                travelType: 'flight',
                tripType: form.tripType,
                departureDate: form.departureDate?.toISOString(),
                returnDate: form.returnDate?.toISOString(),
                numberOfAdults: form.adults,
                numberOfChildren: form.children + form.infants,
                cabin: form.cabinType,
            };

            const resultUrl = createResultUrl({ state: form, url: flightResultUrl, widget });

            if (enableHotelSearch && form.openHotelSearch) {
                // Pulse tracker id: TR_ev09
                trackSearchClick('Search flight and hotel', '/reise', 'flight', position, item);
                window.open(resultUrl);
                window.location.assign(flightHotelUrl(form));
            } else {
                // Pulse tracker id: TR_ev01
                trackSearchClick('Search flight', '/reise', 'flight', position, item);
                window.location.assign(resultUrl);
            }
        }
    };

    const invalid = {
        origin: !pristine && !form.origin,
        destination: !pristine && !form.destination,
        sameOriginDestination: !pristine && form.origin === form.destination,
        origin2: !pristine && !form.origin2,
        destination2: !pristine && !form.destination2,
        sameOriginDestination2: !pristine && form.origin2 === form.destination2,
        departureDate: !pristine && !form.departureDate,
        returnDate: !pristine && form.tripType !== 'oneway' && !form.returnDate,
        returnDateAfterDeparture: !pristine && form.returnDate && form.departureDate && form.returnDate < form.departureDate,
    };

    const validationErrors = {
        origin: invalid.origin ? t('flight.validation.origin') : null,
        destination: invalid.destination ? t('flight.validation.destination') : null,
        sameOriginDestination: invalid.sameOriginDestination ? t('flight.validation.sameOriginDestination') : null,
        origin2: invalid.origin2 ? t('flight.validation.origin') : null,
        destination2: invalid.destination2 ? t('flight.validation.destination') : null,
        sameOriginDestination2: invalid.sameOriginDestination2 ? t('flight.validation.sameOriginDestination') : null,
        departureDate: invalid.departureDate ? t('flight.validation.departureDate') : null,
        returnDate: invalid.returnDate ? t('flight.validation.returnDate') : null,
        returnDateAfterDeparture: invalid.returnDateAfterDeparture ? 'Hjemreise dato må være etter utreise dato' : null,
    };

    const DateRangePickerWrapper = (children: ReactNode | ReactNode[]) => (
        <DateRangePicker
            startDate={form.departureDate}
            endDate={form.returnDate}
            isDayDisabled={isNotWithinOneYearOfToday}
            minimumNights={0}
            onChange={(newDates) => dispatch(actions.setDates(newDates))}>
            {children}
        </DateRangePicker>
    );

    const renderAutocomplete = (id: string, className?: string) => (
        <AutocompleteSwap
            id={id}
            originName={form.originName}
            originError={validationErrors.origin}
            originOnSelect={(option) => dispatch(actions.setOrigin(option))}
            destinationName={form.destinationName}
            destinationError={validationErrors.destination ?? validationErrors.sameOriginDestination}
            destinationOnSelect={(option) => dispatch(actions.setDestination(option))}
            onSwap={() => dispatch(actions.swapOriginDestination())}
            url={flightAutocompleteUrl}
            className={className}
        />
    );

    const renderPassengersAndCabin = (id: string) => (
        <PassengersAndCabin
            id={`passengers-${id}`}
            adults={form.adults}
            childrenCount={form.children}
            infants={form.infants}
            setPassenger={(data) => dispatch(actions.setPassenger(data))}
            cabinType={form.cabinType}
            setCabinType={(cabinType) => dispatch(actions.setCabinType(cabinType))}
        />
    );

    const handleDateChange = (field: 'startDate' | 'endDate') => (newDate) => {
        dispatch(
            actions.setDates({
                startDate: field === 'startDate' ? newDate.startDate : form.departureDate,
                endDate: field === 'endDate' ? newDate.startDate : form.returnDate,
            }),
        );
    };

    return (
        <section data-testid="flightSearch" aria-label={t('flight.title')}>
            <Tabs contained active={form.tripType} onChange={setTripType} className="sm:mx-32">
                <Tab label={t('flight.tripType.roundtrip')} name="roundtrip" />
                <Tab label={t('flight.tripType.oneway')} name="oneway" />
                <Tab label={t('flight.tripType.openjaw')} name="openjaw" />
            </Tabs>
            <div className="bg-aqua-50 rounded-b-8 sm:rounded-8 p-16">
                <TabPanel name="roundtrip">
                    <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-16">
                        {renderAutocomplete('roundtrip')}
                        {unleash?.metaDatePicker ? (
                            <MetaDatePickerWrapper
                                startDate={form.departureDate}
                                endDate={form.returnDate}
                                tripType="roundtrip"
                                visibleMonths={2}
                                useRange={true}
                                origin={form.origin}
                                destination={form.destination}
                                onDateChange={({ startDate, endDate }) => {
                                    dispatch(actions.setDates({ startDate, endDate }));
                                }}>
                                <TextField
                                    label={t('flight.outbound.label')}
                                    id="date-roundtrip-outbound"
                                    data-testid="date-roundtrip-outbound"
                                    error={invalid.departureDate}
                                    helpText={validationErrors.departureDate}
                                />
                                <TextField
                                    label={t('flight.inbound.label')}
                                    id="date-roundtrip-inbound"
                                    data-testid="date-roundtrip-inbound"
                                    error={invalid.returnDate}
                                    helpText={validationErrors.returnDate}
                                />
                            </MetaDatePickerWrapper>
                        ) : (
                            DateRangePickerWrapper(
                                <div className="grid grid-cols-2 gap-x-8">
                                    <DatePickerInput
                                        dateField="startDate"
                                        as={TextField}
                                        id="date-roundtrip-outbound"
                                        label={t('flight.outbound.label')}
                                        placeholder={t('flight.outbound.placeholder')}
                                        data-testid="date-roundtrip-outbound"
                                        error={invalid.departureDate}
                                        helpText={validationErrors.departureDate}
                                    />
                                    <DatePickerInput
                                        dateField="endDate"
                                        as={TextField}
                                        id="date-roundtrip-inbound"
                                        label={t('flight.inbound.label')}
                                        placeholder={t('flight.inbound.placeholder')}
                                        data-testid="date-roundtrip-inbound"
                                        error={invalid.returnDate}
                                        helpText={validationErrors.returnDate}
                                    />
                                    <DatePickerPopover className="date-popover" attachTo="startDate">
                                        <DatePickerCalendar numberOfMonths={numberOfMonths} />
                                    </DatePickerPopover>
                                </div>,
                            )
                        )}
                        {renderPassengersAndCabin('roundtrip')}
                    </div>
                </TabPanel>
                <TabPanel name="oneway">
                    <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-16">
                        {renderAutocomplete('oneway')}
                        {unleash?.metaDatePicker ? (
                            <MetaDatePickerWrapper
                                startDate={form.departureDate}
                                tripType="oneway"
                                visibleMonths={2}
                                useRange={false}
                                origin={form.origin}
                                destination={form.destination}
                                onDateChange={({ startDate }) => {
                                    dispatch(actions.setDates({ startDate }));
                                }}>
                                <TextField
                                    label={t('flight.outbound.label')}
                                    id="date-oneway"
                                    data-testid="date-oneway"
                                    error={invalid.departureDate}
                                    helpText={validationErrors.departureDate}
                                />
                            </MetaDatePickerWrapper>
                        ) : (
                            <DatePicker
                                date={form.departureDate}
                                isDayDisabled={isNotWithinOneYearOfToday}
                                onChange={(newDate) => dispatch(actions.setDates({ startDate: newDate }))}>
                                <DatePickerInput
                                    as={TextField}
                                    id="date-oneway"
                                    label={t('flight.outbound.label')}
                                    placeholder={t('flight.outbound.placeholder')}
                                    data-testid="date-oneway"
                                    error={invalid.departureDate}
                                    helpText={validationErrors.departureDate}
                                />
                                <DatePickerPopover className="date-popover">
                                    <DatePickerCalendar numberOfMonths={numberOfMonths} />
                                </DatePickerPopover>
                            </DatePicker>
                        )}
                        {renderPassengersAndCabin('oneway')}
                    </div>
                </TabPanel>
                <TabPanel name="openjaw">
                    {DateRangePickerWrapper(
                        <div className="grid sm:grid-cols-3 md:grid-cols-5 gap-16">
                            {renderAutocomplete('openjaw', 'md:col-span-4')}
                            {unleash?.metaDatePicker ? (
                                <FrontpageDatePickerWrapper
                                    useRange={false}
                                    startDate={form.departureDate}
                                    upperDateLimit={getOneYearFromToday()}
                                    onDateChange={handleDateChange('startDate')}>
                                    <TextField
                                        label={t('flight.outbound.label')}
                                        id="date-openjaw-outbound"
                                        data-testid="date-openjaw-outbound"
                                        error={validationErrors.departureDate}
                                        helpText={validationErrors.departureDate}
                                    />
                                </FrontpageDatePickerWrapper>
                            ) : (
                                <>
                                    <DatePickerInput
                                        dateField="startDate"
                                        as={TextField}
                                        id="date-openjaw-outbound"
                                        label={t('flight.outbound.label')}
                                        placeholder={t('flight.outbound.placeholder')}
                                        data-testid="date-openjaw-outbound"
                                        error={invalid.departureDate}
                                        helpText={validationErrors.departureDate}
                                    />
                                    {/* @ts-ignore - weird complexity error? */}
                                    <DatePickerPopover className="date-popover" attachTo="focusedDate">
                                        <DatePickerCalendar numberOfMonths={numberOfMonths} />
                                    </DatePickerPopover>
                                </>
                            )}
                            <AutocompleteSwap
                                id="openjaw2"
                                originName={form.originName2}
                                originError={validationErrors.origin2}
                                originOnSelect={(option) => dispatch(actions.setOrigin2(option))}
                                destinationName={form.destinationName2}
                                destinationError={validationErrors.destination2 ?? validationErrors.sameOriginDestination2}
                                destinationOnSelect={(option) => dispatch(actions.setDestination2(option))}
                                onSwap={() => dispatch(actions.swapOriginDestination2())}
                                url={flightAutocompleteUrl}
                                className="md:col-span-4"
                            />
                            {unleash?.metaDatePicker ? (
                                <FrontpageDatePickerWrapper
                                    useRange={false}
                                    startDate={form.returnDate}
                                    onDateChange={handleDateChange('endDate')}
                                    upperDateLimit={getOneYearFromToday()}
                                    disabledDateRanges={form.departureDate ? [{ start: today, end: form.departureDate }] : []}>
                                    <TextField
                                        label={t('flight.inbound.label')}
                                        id="date-openjaw-inbound"
                                        data-testid="date-openjaw-inbound"
                                        error={validationErrors.returnDate || validationErrors.returnDateAfterDeparture}
                                        helpText={[validationErrors.returnDate, validationErrors.returnDateAfterDeparture]
                                            .filter(Boolean)
                                            .join(' ')}
                                    />
                                </FrontpageDatePickerWrapper>
                            ) : (
                                <DatePickerInput
                                    dateField="endDate"
                                    as={TextField}
                                    id="date-openjaw-inbound"
                                    label={t('flight.inbound.label')}
                                    placeholder={t('flight.inbound.placeholder')}
                                    data-testid="date-openjaw-inbound"
                                    error={invalid.returnDate}
                                    helpText={validationErrors.returnDate}
                                />
                            )}
                            <div className="sm:col-span-2">{renderPassengersAndCabin('openjaw')}</div>
                        </div>,
                    )}
                </TabPanel>
                {enableHotelSearch && (
                    <div className="input-toggle mt-16 hidden md:block">
                        <input
                            type="checkbox"
                            id="openHotelSearch"
                            data-testid="openHotelSearch"
                            checked={form.openHotelSearch}
                            onChange={(e) => dispatch(actions.setOpenHotelSearch(e.target.checked))}
                        />
                        <label htmlFor="openHotelSearch" className="truncate">
                            {t('flight.openHotelSearch')}
                        </label>
                    </div>
                )}
                <div className="mt-24">
                    <Button
                        type="submit"
                        primary
                        onClick={handleSubmit}
                        data-testid="flightSearchButton"
                        className="px-64 w-full max-w-full sm:w-auto sm:max-w-auto">
                        {t('action.search')}
                    </Button>
                </div>
            </div>
        </section>
    );
};
