import type { RangeSelectionProps, SingleSelectionProps } from '../BasicDatePicker';

type DatePickerChildrenProps<T> = T extends true ? RangeSelectionProps['children'] : SingleSelectionProps['children'];

const useDatePickerChildValidation = <T extends boolean>(useRange: T, children: DatePickerChildrenProps<T>) => {
    const childArray = Array.isArray(children) ? children : [children];

    const prohibitedAttributes = ['onChange', 'onClick', 'onKeyDown', 'onBlur', 'type', 'className'];

    switch (true) {
        case !useRange && childArray.length !== 1:
            throw new Error(
                'DatePicker requires exactly one TextField when useRange is false. If you want to use two inputs, set useRange to true.',
            );

        case useRange && childArray.length !== 2:
            throw new Error(
                'DatePicker requires exactly two TextField components when useRange is true. If you want to use a single input, set useRange to false.',
            );

        case childArray.some((child) => prohibitedAttributes.some((attr) => attr in child.props)):
            throw new Error(
                `DatePicker does not allow the following attributes on TextField components as they interfere with the internal logic: ${prohibitedAttributes.join(
                    ', ',
                )}.`,
            );

        default:
            break;
    }
};

export default useDatePickerChildValidation;
