import { type ReactElement, type KeyboardEvent, type FocusEvent, cloneElement, useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import {
    formatDate,
    handleAutoFormatOnBlur,
    handleDateInputChange,
    Languages,
    parseDate,
    Direction,
    handleDateInputKeyDown,
} from '../../util/calendar';

import type { SharedDatePickerProps } from './types/DatePickerProps';

import useKeyboardNavigation from './hooks/useKeyboardNavigation';
import useLockBodyScroll from './hooks/useLockBodyScroll';
import useClickOutside from './hooks/useClickOutside';
import useDatePickerChildValidation from './hooks/useDatePickerChildValidation';

import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useDateNavigation } from './hooks/useDateNavigation';
import { useUpdateDateRange } from './hooks/useUpdateDateRange';
import { useFocusTrap } from './hooks/useFocusTrap';
import { useSyncDisplayedDate } from './hooks/useSyncDisplayedDate';
import { useUpdateSingleDate } from './hooks/useUpdateSingleDate';
import { useInitializeDateRange } from './hooks/useInitializeDateRange';

import { NavBtn } from '../calendar/NavBtn';
import { CloseButton } from '../calendar/CloseButton';
import { MonthPanel } from './components/MonthPanel';

import styles from './BasicDatePicker.module.css';

import type { TextField as FabricTextField } from '@fabric-ds/react';

export interface SingleSelectionProps extends Omit<SharedDatePickerProps, 'language'> {
    language?: Languages;
    onDateChange?: (dates: { startDate: Date | null }) => void;
    useRange: false;
    children: ReactElement<typeof FabricTextField>;
}

export interface RangeSelectionProps extends Omit<SharedDatePickerProps, 'language'> {
    language?: Languages;
    onDateChange?: (dates: { startDate: Date | null; endDate: Date | null }) => void;
    useRange: true;
    children: [ReactElement<typeof FabricTextField>, ReactElement<typeof FabricTextField>];
}

export const BasicDatePicker = ({
    onDateChange,
    startDate: initialStartDate = null,
    endDate: initialEndDate = null,
    language = Languages.NO,
    visibleMonths,
    useRange,
    hidePaddingDays = true,
    dayClassName,
    modalFooter,
    modalHeader,
    children,
    dateData,
    upperDateLimit,
    className,
    setDisplayedDate,
    disabledDateRanges,
}: SingleSelectionProps | RangeSelectionProps) => {
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
    const [inputStartDate, setInputStartDate] = useState<string>(formatDate(initialStartDate));
    const [inputEndDate, setInputEndDate] = useState<string>(formatDate(initialEndDate));
    const [endDateFirst, setEndDateFirst] = useState<boolean>(false);
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
    const [focusedDate, setFocusedDate] = useState<Date>(new Date());
    const [displayDates, setDisplayDates] = useState<Date>(new Date());
    const [isStartDateFocused, setIsStartDateFocused] = useState<boolean>(false);
    const [isEndDateFocused, setIsEndDateFocused] = useState<boolean>(false);

    const isNorwegian = language === Languages.NO;

    const isSmallScreen = useBreakpoints({ breakpoint: 'sm' });
    const isMediumScreen = useBreakpoints({ breakpoint: 'md' });

    const effectiveVisibleMonths = useMemo(() => (isSmallScreen ? 1 : visibleMonths), [isSmallScreen, visibleMonths]);

    const modalRef = useRef<HTMLDivElement | null>(null);

    const { handleNextPrevMonth, isPrevDisabled, isNextDisabled } = useDateNavigation({
        visibleMonths: effectiveVisibleMonths,
        displayDates,
        setDisplayDates,
        modalRef,
        upperDateLimit,
    });

    const showCalendar = (isEndDate: boolean) => {
        setIsCalendarVisible(true);
        setEndDateFirst(isEndDate && !startDate);

        const newDisplayDate = startDate
            ? new Date(startDate.getFullYear(), startDate.getMonth(), 1)
            : endDate
              ? new Date(endDate.getFullYear(), endDate.getMonth(), 1)
              : displayDates;

        setDisplayDates(newDisplayDate);
    };

    const hideCalendar = useCallback(() => {
        setIsCalendarVisible(false);
        setIsStartDateFocused(false);
        setIsEndDateFocused(false);
    }, [isNorwegian]);

    useFocusTrap(isCalendarVisible, modalRef);
    useLockBodyScroll(isCalendarVisible && isSmallScreen);
    useDatePickerChildValidation(useRange, children);
    useClickOutside(modalRef, hideCalendar);
    useKeyboardNavigation(focusedDate, setFocusedDate, modalRef, hideCalendar, handleNextPrevMonth, effectiveVisibleMonths, displayDates);
    useSyncDisplayedDate(displayDates, setDisplayedDate);

    useInitializeDateRange({
        initialStartDate,
        initialEndDate,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        setInputStartDate,
        setInputEndDate,
        formatDate,
    });

    const updateDateRange = useRange
        ? useUpdateDateRange({
              startDate,
              endDate,
              endDateFirst,
              setStartDate: (date) => {
                  setStartDate(date);
                  setInputStartDate(formatDate(date));
                  onDateChange?.({ startDate: date, endDate });
              },
              setEndDate: (date) => {
                  setEndDate(date);
                  setInputEndDate(formatDate(date));
                  onDateChange?.({ startDate, endDate: date });
              },
              setInputStartDate,
              setInputEndDate,
              setEndDateFirst,
              setIsStartDateFocused,
              setIsEndDateFocused,
          })
        : useUpdateSingleDate({
              startDate,
              setStartDate: (date) => {
                  setStartDate(date);
                  setInputStartDate(formatDate(date));
                  if (useRange) {
                      (onDateChange as RangeSelectionProps['onDateChange'])?.({ startDate: date, endDate });
                  } else {
                      (onDateChange as SingleSelectionProps['onDateChange'])?.({ startDate: date });
                  }
              },
              setInputStartDate,
              setIsStartDateFocused,
          });

    const commonProps = {
        readOnly: isCalendarVisible && isSmallScreen,
        ...(isSmallScreen ? { inputMode: 'none' } : {}),
        ...(isSmallScreen ? { onFocus: (e: FocusEvent<HTMLInputElement>) => e.target.blur() } : {}),
        placeholder: isNorwegian ? 'dd.mm.åååå' : 'dd.mm.yyyy',
        'aria-haspopup': 'grid',
        'aria-expanded': isCalendarVisible,
        role: 'combobox',
        type: 'text',
    };

    const dateProps: Pick<SingleSelectionProps, 'onDateChange' | 'useRange'> | Pick<RangeSelectionProps, 'onDateChange' | 'useRange'> =
        useRange ? { useRange: true, onDateChange } : { useRange: false, onDateChange };

    const generateInputProps = (isEndDate: boolean, value: string, focused: boolean) => ({
        ...commonProps,
        value,
        onClick: () => {
            showCalendar(isEndDate);
            setIsStartDateFocused(!isEndDate);
            setIsEndDateFocused(isEndDate);
        },
        onKeyDown: (e: KeyboardEvent<HTMLInputElement>) =>
            handleDateInputKeyDown(
                e,
                value,
                isEndDate,
                dateProps,
                setStartDate,
                setEndDate,
                showCalendar,
                setIsStartDateFocused,
                setIsEndDateFocused,
            ),
        onChange: (e: { target: { value: string } }) => {
            handleDateInputChange(
                e.target.value,
                isEndDate ? setInputEndDate : setInputStartDate,
                isEndDate ? setEndDate : setStartDate,
                parseDate,
                isEndDate,
                setEndDateFirst,
                setIsStartDateFocused,
                setIsEndDateFocused,
                startDate,
                endDate,
                dateProps,
            );
        },
        onBlur: (e: { target: { value: string } }) => {
            const handler = isEndDate ? setInputEndDate : setInputStartDate;
            const dateSetter = isEndDate ? setEndDate : setStartDate;
            handleAutoFormatOnBlur(e.target.value, handler, dateSetter, parseDate);
        },
        className: cn('date-picker__input', focused ? 'date-picker__visual-focus' : 'date-picker__no-visual-focus', {
            'date-picker__input--small-screen': isSmallScreen,
            'date-picker__input--medium-screen': isMediumScreen,
            'date-picker__input--start-date': !isEndDate,
            'date-picker__input--end-date': isEndDate,
        }),
    });

    const childArray = Array.isArray(children) ? children : [children];

    const childrenWithProps = childArray.map((child, index) =>
        cloneElement(child, {
            key: child.key || `date-picker-child-${index}`,
            ...(index === 0
                ? generateInputProps(false, inputStartDate, isStartDateFocused)
                : generateInputProps(true, inputEndDate, isEndDateFocused)),
            ...child.props,
        }),
    );

    return (
        <>
            {isCalendarVisible && isSmallScreen && <div className={styles['date-picker__overlay']} onClick={hideCalendar} />}
            <div className={cn(styles['date-picker'], className)} ref={modalRef}>
                <fieldset
                    id="basic-date-picker-fieldset"
                    className={cn('date-picker__fieldset', {
                        'date-picker__fieldset--small-screen': isSmallScreen,
                        'date-picker__fieldset--medium-screen': isMediumScreen,
                        'date-picker__fieldset--single-date': !useRange,
                    })}>
                    <legend className="sr-only">
                        {useRange ? (isNorwegian ? 'Velg datoer' : 'Choose date range') : isNorwegian ? 'Velg dato' : 'Choose date'}
                    </legend>
                    {childrenWithProps}
                </fieldset>
                {isCalendarVisible && (
                    <div
                        // biome-ignore lint/a11y/useSemanticElements: dialog tag has default functionality that interferes with the date picker
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="basic-date-picker-fieldset"
                        className={cn('date-picker__modal', {
                            'date-picker__modal--small-screen': isSmallScreen,
                            'date-picker__modal--medium-screen': isMediumScreen,
                            'date-picker__modal--single-month': !isSmallScreen && effectiveVisibleMonths === 1,
                            'date-picker__modal--open': isCalendarVisible,
                            'date-picker__modal--closed': !isCalendarVisible,
                        })}>
                        {modalHeader}
                        <nav
                            className={cn('date-picker__modal-header', {
                                'date-picker__modal-header--small-screen': isSmallScreen,
                                'date-picker__modal-header--medium-screen': isMediumScreen,
                            })}>
                            <NavBtn direction={Direction.Left} onClick={() => handleNextPrevMonth('prev')} disabled={isPrevDisabled} />
                            <NavBtn direction={Direction.Right} onClick={() => handleNextPrevMonth('next')} disabled={isNextDisabled} />
                        </nav>
                        <div
                            className={cn('date-picker__month-panel-group', {
                                'date-picker__month-panel-group--small-screen': isSmallScreen,
                            })}>
                            {[...Array(effectiveVisibleMonths)].map((_, index) => {
                                const month = (displayDates.getMonth() + index) % 12;
                                const year =
                                    displayDates.getMonth() + index >= 12 ? displayDates.getFullYear() + 1 : displayDates.getFullYear();
                                return (
                                    <MonthPanel
                                        key={`month-panel-${index + 1}`}
                                        year={year}
                                        month={month}
                                        startDate={startDate}
                                        endDate={endDate}
                                        language={Languages.NO}
                                        updateDateRange={updateDateRange}
                                        hidePaddingDays={hidePaddingDays}
                                        focusedDate={focusedDate}
                                        dateData={dateData}
                                        dayClassName={dayClassName}
                                        upperDateLimit={upperDateLimit}
                                        disabledDateRanges={disabledDateRanges}
                                    />
                                );
                            })}
                        </div>

                        <div
                            className={cn('date-picker__modal-footer', {
                                'date-picker__modal-footer--small-screen': isSmallScreen,
                                'date-picker__modal-footer--medium-screen': isMediumScreen,
                            })}>
                            <div className="date-picker__modal-footer__optional">{modalFooter}</div>
                            <CloseButton onClick={hideCalendar} setOpen={setIsCalendarVisible} text="Bruk" />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
