import { type RefObject, useCallback, useMemo } from 'react';

import { useSwipe } from './useSwipe';

interface DateNavigationProps {
    visibleMonths: number;
    displayDates: { getFullYear: () => number; getMonth: () => number };
    setDisplayDates: (newDate: Date) => void;
    modalRef: RefObject<HTMLDivElement>;
    upperDateLimit?: Date;
}

export const useDateNavigation = ({ visibleMonths, displayDates, setDisplayDates, modalRef, upperDateLimit }: DateNavigationProps) => {
    const today = useMemo(() => new Date(), []);

    const getNewDate = useCallback(
        (action: 'prev' | 'next') => {
            const currentYear = displayDates.getFullYear();
            const currentMonth = displayDates.getMonth();
            return new Date(currentYear, currentMonth + (action === 'next' ? visibleMonths : -visibleMonths), 1);
        },
        [displayDates, visibleMonths],
    );

    const isDateInPast = useCallback(
        (date: Date) => {
            return date < new Date(today.getFullYear(), today.getMonth(), 1);
        },
        [today],
    );

    const isDateBeyondLimit = useCallback(
        (date: Date) => {
            return upperDateLimit ? date > new Date(upperDateLimit.getFullYear(), upperDateLimit.getMonth(), 1) : false;
        },
        [upperDateLimit],
    );

    const handleNextPrevMonth = useCallback(
        (action: 'prev' | 'next') => {
            const currentYear = displayDates.getFullYear();
            const currentMonth = displayDates.getMonth();
            const newMonth = action === 'next' ? currentMonth + visibleMonths : currentMonth - visibleMonths;
            const newDate = new Date(currentYear, newMonth, 1);

            if (action === 'prev' && isDateInPast(newDate)) {
                setDisplayDates(new Date(today.getFullYear(), today.getMonth(), 1));
            } else {
                setDisplayDates(newDate);
            }
        },
        [displayDates, visibleMonths, today, setDisplayDates, isDateInPast],
    );

    const isPrevDisabled = useMemo(() => {
        if (!displayDates) return false;

        const firstVisibleMonth = new Date(displayDates.getFullYear(), displayDates.getMonth(), 1);
        const previousFirstMonth = new Date(firstVisibleMonth.getFullYear(), firstVisibleMonth.getMonth() - visibleMonths, 1);
        const earliestNavigableMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        return previousFirstMonth < earliestNavigableMonth && firstVisibleMonth <= earliestNavigableMonth;
    }, [displayDates, today, visibleMonths]);

    const isNextDisabled = useMemo(() => {
        if (!upperDateLimit) return false;

        const nextDate = getNewDate('next');

        return isDateBeyondLimit(nextDate);
    }, [getNewDate, isDateBeyondLimit, upperDateLimit]);

    useSwipe(modalRef, {
        onSwipeLeft: () => handleNextPrevMonth('next'),
        onSwipeRight: () => handleNextPrevMonth('prev'),
    });

    return { handleNextPrevMonth, isPrevDisabled, isNextDisabled, modalRef };
};
