import { useEffect, useReducer, useState } from 'react';
import getConfig from 'next/config';
import { DatePickerCalendar, DatePickerInput, DatePickerPopover, DateRangePicker } from '@finn-no/legacy-fabric-datepicker';
import { Select, TextField } from '@fabric-ds/react';

import { t } from '../../locale/texts';
import { isMobile } from '../../util/mobile';
import { formatISODate, isBefore, isPast, parseISO, safeFormatISODate } from '../../util/datetime-fns';
import { getLastSearchCar, storeLastSearchCar } from '../common/lastSearch';
import { carAutocompleteMapper } from '../common/autocompleteMapper';
import { trackSearchClick } from '../common/tracking';

import actions, { reducer } from './carActions';
import { carDefaultState } from './carDefaultState';

import { Autocomplete, type AutocompleteOption } from '../common/Autocomplete';
import { CarSearchSubmit } from './CarSearchSubmit';

import { FrontpageDatePickerWrapper } from '../../components/frontpageDatePickerWrapper/FrontpageDatePickerWrapper';

import cn from 'classnames';

import styles from './CarSearch.module.css';

const timeOptions: string[] = [];
for (let h = 0; h < 24; h++) {
    const hr = `00${h}`.substr(-2, 2);
    timeOptions.push(`${hr}:00`);
    timeOptions.push(`${hr}:30`);
}

const ageOptions: number[] = [];
for (let i = 18; i < 100; i++) {
    ageOptions.push(i);
}

const dateTimeIsAfter = (date: Date | number, time: string, thenDate?: Date | number, thenTime?: string): boolean => {
    try {
        const dateTime = parseISO(`${formatISODate(date)}T${time}`);
        const thenDateTime = thenDate && thenTime ? parseISO(`${formatISODate(thenDate)}T${thenTime}`) : Date.now();
        return isBefore(dateTime, thenDateTime);
    } catch {
        return false;
    }
};

type CarSearchProps = {
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    prefill?: Record<string, any>;
    unleash?: Record<string, boolean>;
    widget?: string;
    position: string;
};

const {
    publicRuntimeConfig: { carAutocompleteUrl },
} = getConfig();

export const CarSearch = ({ prefill, unleash, widget, position }: CarSearchProps) => {
    const [pristine, setPristine] = useState(true);
    const [state, dispatch] = useReducer(reducer, carDefaultState);

    useEffect(() => {
        dispatch(actions.allFieldsChanged({ ...getLastSearchCar(), ...prefill }));
    }, []);

    const setPickupLocation = (payload: AutocompleteOption | null): void => {
        if (payload?.placeId && !Number.parseInt(payload?.code ?? '', 10)) {
            // Fetches Google place ID if needed
            fetch(`https://www.finn.no/travel-api/places?id=${payload?.placeId}`)
                .then((response) => response.json())
                .then((result) => {
                    dispatch(
                        actions.setPickupLocationParams({
                            text: payload?.text,
                            latitude: result?.place?.latitude,
                            longitude: result?.place?.longitude,
                        }),
                    );
                });
        } else {
            dispatch(actions.setPickupLocationParams(payload));
        }
    };

    const calculateInvalidFields = (isPristine: boolean = pristine): Record<string, boolean> => ({
        pickupLocation:
            !isPristine &&
            (!(state.pickupLocationId || state.pickupLocation) || !(state.pickupLocationLatitude && state.pickupLocationLongitude)),
        returnLocation:
            !isPristine &&
            !state.returnLocationIsSame &&
            (!(state.returnLocationId || state.returnLocation) || !(state.returnLocationLatitude && state.returnLocationLongitude)),
        pickupDate: !isPristine && !dateTimeIsAfter(state.pickupDate, state.pickupTime),
        pickupTime: !isPristine && !dateTimeIsAfter(state.pickupDate, state.pickupTime),
        returnDate: !isPristine && !dateTimeIsAfter(state.returnDate, state.returnTime, state.pickupDate, state.pickupTime),
        returnTime: !isPristine && !dateTimeIsAfter(state.returnDate, state.returnTime, state.pickupDate, state.pickupTime),
        driverAge: !isPristine && !state.driverAge,
    });

    const handleSubmit = (event): void => {
        setPristine(false);

        const invalid = calculateInvalidFields(false);
        if (Object.keys(invalid).some((key) => invalid[key])) {
            event.preventDefault();
        } else {
            const item = {
                id: 'finntravel',
                type: 'Travel',
                destination: state.pickupLocation,
                travelType: 'carrental',
                departureDate: `${safeFormatISODate(state.pickupDate)}T${state.pickupTime}:00.000Z`,
                returnDate: `${safeFormatISODate(state.returnDate)}T${state.returnTime}:00.000Z`,
            };
            trackSearchClick('Search carrental', '/reise/leiebil', 'car_rental', position, item, 'Form');
            storeLastSearchCar(state);
        }
    };

    const numberOfMonths = isMobile() ? 1 : 2;
    const invalidFields = calculateInvalidFields();

    // Grid positioning "hacks" to place elements in non-linear order
    const gridCommon = `md:row-start-2 md:row-end-3${widget ? '' : ' lg:col-start-auto lg:col-end-auto lg:row-start-auto lg:row-end-auto'}`;
    const gridReturnDate = `md:col-start-2 md:col-end-3 ${gridCommon}`;
    const gridToggles = `md:col-start-1 md:col-end-2 ${gridCommon}`;

    return unleash?.basicDatePicker ? (
        <div className={styles.CarSearch}>
            <fieldset className={styles.fieldsetLocation}>
                <Autocomplete
                    id="pickupLocation"
                    label={t('car.autocomplete.pickup.label')}
                    placeholder={t('car.autocomplete.pickup.placeholder')}
                    defaultValue={state.pickupLocation}
                    onSelect={(opt) => setPickupLocation(opt)}
                    url={carAutocompleteUrl}
                    mapOptions={carAutocompleteMapper}
                    minChars={3}
                    error={invalidFields.pickupLocation ? t('car.validation.pickupLocation') : undefined}
                />
                {!state.returnLocationIsSame && (
                    <Autocomplete
                        id="returnLocation"
                        label={t('car.autocomplete.return.label')}
                        placeholder={t('car.autocomplete.return.placeholder')}
                        defaultValue={state.returnLocation}
                        onSelect={(o) => dispatch(actions.setReturnLocation(o))}
                        url={carAutocompleteUrl}
                        mapOptions={carAutocompleteMapper}
                        minChars={3}
                        error={invalidFields.returnLocation ? t('car.validation.returnLocation') : undefined}
                    />
                )}
            </fieldset>
            <div className={styles.Group}>
                <div className={styles.DateAndTimeGroup}>
                    <FrontpageDatePickerWrapper
                        useRange
                        className={styles.fieldsetDate}
                        startDate={state.pickupDate}
                        endDate={state.returnDate}
                        onDateChange={({ startDate, endDate }) => dispatch(actions.setDates({ startDate, endDate }))}>
                        <TextField
                            id="pickupDate"
                            label={t('car.pickup.date')}
                            error={invalidFields.pickupDate}
                            helpText={invalidFields.pickupDate && t('car.validation.pickupDate')}
                        />
                        <TextField
                            id="returnDate"
                            label={t('car.return.date')}
                            error={invalidFields.returnDate}
                            helpText={
                                invalidFields.returnDate &&
                                (state.returnDate ? t('car.validation.returnDateFuture') : t('car.validation.returnDate'))
                            }
                        />
                    </FrontpageDatePickerWrapper>
                    <fieldset className={styles.fieldsetTime}>
                        <Select
                            id="pickupTime"
                            data-testid="pickupTime"
                            label="Klokkeslett henting"
                            value={state.pickupTime}
                            onChange={(e) => dispatch(actions.setPickupTime(e.target.value))}>
                            {timeOptions.map((time) => (
                                <option key={`pickup${time}`} value={time}>
                                    {time}
                                </option>
                            ))}
                        </Select>
                        <Select
                            id="returnTime"
                            data-testid="returnTime"
                            label="Klokkeslett retur"
                            value={state.returnTime}
                            onChange={(e) => dispatch(actions.setReturnTime(e.target.value))}>
                            {timeOptions.map((time) => (
                                <option key={`return${time}`} value={time}>
                                    {time}
                                </option>
                            ))}
                        </Select>
                    </fieldset>
                </div>
                <fieldset className={cn(state.returnLocationIsSame ? gridToggles : '', styles.fieldsetCheckbox)}>
                    <div className="input-toggle">
                        <input
                            type="checkbox"
                            id="returnLocationIsSame"
                            data-testid="returnLocationIsSame"
                            checked={state.returnLocationIsSame}
                            onChange={(e) => dispatch(actions.setReturnLocationIsSame(e.target.checked))}
                        />
                        <label htmlFor="returnLocationIsSame" className="truncate">
                            {t('car.sameReturnLocation')}
                        </label>
                    </div>
                    <div className="input-toggle">
                        <input
                            type="checkbox"
                            id="driverAgeIsStandard"
                            data-testid="driverAgeIsStandard"
                            checked={state.driverAgeIsStandard}
                            onChange={(e) => dispatch(actions.setDriverAgeIsStandard(e.target.checked))}
                        />
                        <label htmlFor="driverAgeIsStandard" className="truncate">
                            {t('car.driver.title')}
                        </label>
                    </div>
                    {!state.driverAgeIsStandard && (
                        <Select
                            className={styles.fieldsetAge}
                            data-testid="driverAge"
                            label={t('car.driver.age')}
                            aria-label={t('car.driver.age')}
                            value={state.driverAge}
                            onChange={(e) => dispatch(actions.setDriverAge(e.target.value))}>
                            {ageOptions.map((age) => (
                                <option key={`carAge${age}`} value={age}>
                                    {age}
                                </option>
                            ))}
                        </Select>
                    )}
                </fieldset>
            </div>
            <CarSearchSubmit {...state} handleSubmit={handleSubmit} unleash={unleash} widget={widget} />
        </div>
    ) : (
        <DateRangePicker
            startDate={state.pickupDate}
            endDate={state.returnDate}
            isDayDisabled={isPast}
            onChange={(newDates) => dispatch(actions.setDates(newDates))}
            minimumNights={0}>
            <section className="bg-aqua-50 rounded-8 p-16" data-testid="carRentalSearch" aria-label={t('car.title')}>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-16">
                    <Autocomplete
                        id="pickupLocation"
                        label={t('car.autocomplete.pickup.label')}
                        placeholder={t('car.autocomplete.pickup.placeholder')}
                        defaultValue={state.pickupLocation}
                        onSelect={(opt) => setPickupLocation(opt)}
                        url={carAutocompleteUrl}
                        mapOptions={carAutocompleteMapper}
                        minChars={3}
                        error={invalidFields.pickupLocation ? t('car.validation.pickupLocation') : undefined}
                        className={state.returnLocationIsSame ? undefined : 'lg:col-span-2'}
                    />
                    <div className="grid grid-cols-2 gap-x-8">
                        <DatePickerInput
                            as={TextField}
                            dateField="startDate"
                            id="pickupDate"
                            label={t('car.pickup.date')}
                            placeholder={t('car.pickup.date')}
                            aria-label={t('car.pickup.date')}
                            error={invalidFields.pickupDate}
                            helpText={
                                invalidFields.pickupDate &&
                                (state.pickupDate && state.pickupTime
                                    ? t('car.validation.pickupDateFuture')
                                    : t('car.validation.pickupDate'))
                            }
                        />
                        <Select
                            id="pickupTime"
                            data-testid="pickupTime"
                            label={t('car.pickup.time')}
                            aria-label={t('car.pickup.time')}
                            value={state.pickupTime}
                            onChange={(e) => dispatch(actions.setPickupTime(e.target.value))}>
                            {timeOptions.map((time) => (
                                <option key={`pickup${time}`} value={time}>
                                    {time}
                                </option>
                            ))}
                        </Select>
                    </div>
                    {!state.returnLocationIsSame && (
                        <Autocomplete
                            id="returnLocation"
                            label={t('car.autocomplete.return.label')}
                            placeholder={t('car.autocomplete.return.placeholder')}
                            defaultValue={state.returnLocation}
                            onSelect={(o) => dispatch(actions.setReturnLocation(o))}
                            url={carAutocompleteUrl}
                            mapOptions={carAutocompleteMapper}
                            minChars={3}
                            error={invalidFields.returnLocation ? t('car.validation.returnLocation') : undefined}
                            className={state.returnLocationIsSame ? undefined : 'lg:col-span-2'}
                        />
                    )}
                    <div className={`grid grid-cols-2 gap-x-8 ${state.returnLocationIsSame ? gridReturnDate : ''}`}>
                        <DatePickerInput
                            as={TextField}
                            dateField="endDate"
                            id="returnDate"
                            label={t('car.return.date')}
                            placeholder={t('car.return.date')}
                            aria-label={t('car.return.date')}
                            error={invalidFields.returnDate}
                            helpText={
                                invalidFields.returnDate &&
                                (state.returnDate && state.returnTime
                                    ? t('car.validation.returnDateFuture')
                                    : t('car.validation.returnDate'))
                            }
                        />
                        <Select
                            id="returnTime"
                            data-testid="returnTime"
                            label={t('car.return.time')}
                            aria-label={t('car.return.time')}
                            value={state.returnTime}
                            onChange={(e) => dispatch(actions.setReturnTime(e.target.value))}>
                            {timeOptions.map((time) => (
                                <option key={`return${time}`} value={time}>
                                    {time}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className={`space-y-8 ${state.returnLocationIsSame ? gridToggles : ''}`}>
                        <div className="input-toggle">
                            <input
                                type="checkbox"
                                id="returnLocationIsSame"
                                data-testid="returnLocationIsSame"
                                checked={state.returnLocationIsSame}
                                onChange={(e) => dispatch(actions.setReturnLocationIsSame(e.target.checked))}
                            />
                            <label htmlFor="returnLocationIsSame" className="truncate">
                                {t('car.sameReturnLocation')}
                            </label>
                        </div>
                        <div className="input-toggle">
                            <input
                                type="checkbox"
                                id="driverAgeIsStandard"
                                data-testid="driverAgeIsStandard"
                                checked={state.driverAgeIsStandard}
                                onChange={(e) => dispatch(actions.setDriverAgeIsStandard(e.target.checked))}
                            />
                            <label htmlFor="driverAgeIsStandard" className="truncate">
                                {t('car.driver.title')}
                            </label>
                        </div>
                    </div>
                    {state.driverAgeIsStandard ? null : (
                        <Select
                            data-testid="driverAge"
                            label={t('car.driver.age')}
                            aria-label={t('car.driver.age')}
                            value={state.driverAge}
                            onChange={(e) => dispatch(actions.setDriverAge(e.target.value))}>
                            {ageOptions.map((age) => (
                                <option key={`carAge${age}`} value={age}>
                                    {age}
                                </option>
                            ))}
                        </Select>
                    )}
                </div>
                <CarSearchSubmit {...state} handleSubmit={handleSubmit} unleash={unleash} widget={widget} />
            </section>
            <DatePickerPopover className="date-popover" attachTo="focusedDate">
                <DatePickerCalendar numberOfMonths={numberOfMonths} />
            </DatePickerPopover>
        </DateRangePicker>
    );
};
