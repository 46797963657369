import type { ReactElement } from 'react';

import { BasicDatePicker, type RangeSelectionProps, type SingleSelectionProps } from '../basicDatePicker/BasicDatePicker';
import cn from 'classnames';

import styles from './FrontpageDatePickerWrapper.module.css';

import type { TextField as FabricTextField } from '@fabric-ds/react';

export const FrontpageDatePickerWrapper = ({
    startDate,
    endDate,
    onDateChange,
    className,
    modalFooter,
    upperDateLimit,
    useRange,
    disabledDateRanges,
    children,
}: Omit<SingleSelectionProps, 'visibleMonths'> | Omit<RangeSelectionProps, 'visibleMonths'>) => {
    if (useRange) {
        return (
            <BasicDatePicker
                className={cn(styles.FrontpageDatePicker, className)}
                onDateChange={onDateChange}
                startDate={startDate}
                endDate={endDate}
                useRange={true}
                visibleMonths={2}
                modalFooter={modalFooter}
                upperDateLimit={upperDateLimit}
                disabledDateRanges={disabledDateRanges}>
                {children as [ReactElement<typeof FabricTextField>, ReactElement<typeof FabricTextField>]}
            </BasicDatePicker>
        );
    }

    return (
        <BasicDatePicker
            className={cn(styles.FrontpageDatePicker, className)}
            onDateChange={onDateChange}
            startDate={startDate}
            useRange={false}
            visibleMonths={2}
            modalFooter={modalFooter}
            upperDateLimit={upperDateLimit}
            disabledDateRanges={disabledDateRanges}>
            {children as ReactElement<typeof FabricTextField>}
        </BasicDatePicker>
    );
};
