import type { ReactNode } from 'react';
import cn from 'classnames';

import type { SharedDatePickerProps } from '../types/DatePickerProps';

import { getISOWeekNumber, monthNames } from 'shared/util/calendar';
import { generateDateItems } from '../utils/generateDateItems';

import { useBreakpoints } from '../../../hooks/useBreakpoints';

import { DatePickerDay } from './DatePickerDay';
import { WeekdayHeader } from './WeekdayHeader';

interface MonthPanelProps
    extends Pick<SharedDatePickerProps, 'startDate' | 'endDate' | 'language' | 'dayClassName' | 'disabledDateRanges'> {
    year: number;
    month: number;
    focusedDate: Date | undefined;
    updateDateRange: (date: Date) => void;
    hidePaddingDays: boolean;
    dateData?: Record<string, ReactNode>;
    upperDateLimit?: Date;
}

export const MonthPanel = ({
    year,
    month,
    startDate,
    endDate,
    language,
    updateDateRange,
    hidePaddingDays,
    disabledDateRanges,
    focusedDate,
    dateData,
    dayClassName,
    upperDateLimit,
}: MonthPanelProps) => {
    const isSmallScreen = useBreakpoints({ breakpoint: 'sm' });
    const isMediumScreen = useBreakpoints({ breakpoint: 'md' });
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const numDaysInMonth = new Date(year, month + 1, 0).getDate();
    const dates = generateDateItems(
        year,
        month,
        firstDayOfMonth,
        numDaysInMonth,
        startDate,
        endDate,
        hidePaddingDays,
        upperDateLimit,
        disabledDateRanges,
    );
    const weeks: { date: Date; classNames: string[]; isDisabled: boolean }[][] = [];
    const dateExceedsUpperLimit = upperDateLimit && upperDateLimit < new Date(year, month, 1);

    for (let i = 0; i < dates.length; i += 7) {
        weeks.push(dates.slice(i, i + 7));
    }

    return (
        <table className="date-picker__month-panel">
            <caption
                className={cn('date-picker__month-title', {
                    'date-picker__month-title--small-screen': isSmallScreen,
                    'date-picker__month-title--outside-range': dateExceedsUpperLimit,
                })}>
                {monthNames[language as keyof typeof monthNames][month]} {year}
            </caption>
            <WeekdayHeader language={language} upperDateLimit={dateExceedsUpperLimit} />
            <tbody className="date-picker__calendar">
                {weeks.map((week, rowIndex) => (
                    <tr
                        key={`week-${rowIndex + 1}`}
                        className="date-picker__calendar__table-row"
                        data-week-number={getISOWeekNumber(week[0].date)}>
                        {week.map(({ date, classNames, isDisabled }) => {
                            const formattedDate = date.toISOString().split('T')[0];
                            const dataForDate = dateData?.[formattedDate] ?? null;
                            const dynamicClassName = typeof dayClassName === 'function' ? dayClassName(date) : dayClassName;

                            return (
                                <DatePickerDay
                                    key={date.getTime()}
                                    getDate={date.getDate()}
                                    displayDates={date}
                                    className={cn(...classNames, {
                                        [`date-picker__day--${dynamicClassName}`]: dynamicClassName,
                                        'date-picker__day--has-data': dataForDate !== null,
                                        'date-picker__day--small-screen': isSmallScreen,
                                        'date-picker__day--medium-screen': isMediumScreen,
                                    })}
                                    onClick={() => updateDateRange(date)}
                                    isDisabled={isDisabled}
                                    isFocused={
                                        !!focusedDate &&
                                        date.getDate() === focusedDate.getDate() &&
                                        date.getMonth() === focusedDate.getMonth() &&
                                        date.getFullYear() === focusedDate.getFullYear()
                                    }>
                                    {dataForDate !== null && dataForDate}
                                </DatePickerDay>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
