import { PopularCitiesCard } from './PopularCitiesCard';
import type { SeoPlace, SeoText } from './interfaces';

type PopularCitiesProps = {
    destination?: SeoPlace;
    cities: SeoPlace[];
    cdnUrl: string;
    verticalUrl: string;
    lang: SeoText;
};

export const PopularCities = ({ destination, cities, cdnUrl, verticalUrl, lang }: PopularCitiesProps) => {
    const renderPopularCitiesCard = (dest: SeoPlace, idx: number | string) => (
        <PopularCitiesCard
            destination={dest}
            key={`popularCity${idx}`}
            id={`popCity-${idx}`}
            cdnUrl={cdnUrl}
            verticalUrl={verticalUrl}
            lang={lang}
        />
    );

    return cities?.length > 1 ? (
        <section className="mb-64" aria-labelledby="seo-popular-title">
            <h2 id="seo-popular-title" className="text-center mx-16" data-testid="popular-destinations-heading">
                {destination?.city ? lang.popularCities.headingOther : lang.popularCities.headingDefault}
            </h2>
            <div className="pb-16 text-center px-32 sm:px-64" data-testid="popular-destinations-description">
                {destination ? lang.popularCities.descriptionOther : lang.popularCities.descriptionDefault}
            </div>
            <div className="sm:grid sm:grid-cols-2">
                {renderPopularCitiesCard(cities[0], 'Top1')}
                {renderPopularCitiesCard(cities[1], 'Top2')}
            </div>
            <div className="sm:grid sm:grid-cols-2 md:grid-cols-3">{cities.slice(2).map(renderPopularCitiesCard)}</div>
        </section>
    ) : null;
};
