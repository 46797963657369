import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'ts-debounce';
import classNames from 'classnames';
import { Affix, TextField } from '@fabric-ds/react';

export interface AutocompleteBounds {
    southwest_lat: number;
    southwest_lng: number;
    northeast_lat: number;
    northeast_lng: number;
    latitude?: number;
    longitude?: number;
}
// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
export type AutocompleteResponse = Record<string, any>[];
export type AutocompleteOption = {
    id: string;
    text: string;
    type?: string;
    count?: number;
    cls?: string;
    country?: string;
    city?: string;
    bounds?: AutocompleteBounds;
    placeId?: string;
    code?: string;
};
export type AutocompleteProps = {
    id: string;
    label: string;
    defaultValue?: string | null;
    placeholder: string;
    error?: string;
    url: string;
    headers?: HeadersInit;
    mapOptions: (options: AutocompleteResponse) => AutocompleteOption[];
    onSelect: (data: AutocompleteOption | null) => void;
    minChars?: number;
    className?: string;
};

export const Autocomplete = ({
    id,
    label,
    defaultValue,
    placeholder,
    error,
    url,
    headers = {},
    mapOptions,
    onSelect,
    minChars = 1,
    className,
}: AutocompleteProps) => {
    const [text, setText] = useState<string>(defaultValue ?? '');
    const [options, setOptions] = useState<AutocompleteOption[]>([]);
    const [selected, setSelected] = useState<number>(-1);
    const [focus, setFocus] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [noResults, setNoResults] = useState<boolean>(false);

    useEffect(() => {
        setText(defaultValue ?? '');
    }, [defaultValue]);

    let fetchCount = 0;

    const selectOption = (data: AutocompleteOption): void => {
        setText(data.text);
        setFocus(false);
        onSelect(data);
    };

    const selectCurrentOption = (): void => {
        if (selected >= 0 && selected < options.length) {
            selectOption(options[selected]);
        }
    };

    const fetchOptions = (value: string): void => {
        const fetchNum = ++fetchCount;

        setFetching(true);

        const encodedValue = encodeURIComponent(value);
        fetch(`${url}${encodedValue}`, { headers })
            .then((res) => res.json())
            .then((json) => {
                if (fetchNum === fetchCount) {
                    const validResult = Array.isArray(json) && json.length;

                    if (validResult) {
                        setOptions(mapOptions(json));
                        setNoResults(false);
                    } else {
                        setOptions([]);
                        setNoResults(true);
                    }
                }
            })
            .catch((err) => {
                setOptions([]);
                setNoResults(true);
                console.error(err);
            })
            .finally(() => {
                setFocus(true);
                setFetching(false);
                setSelected(0);
            });
    };

    // Include useMemo to avoid React creating a new instance of the debounced function on each render..
    const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 200), [url, mapOptions]);

    const onKeyDown = (e): void => {
        switch (e.key) {
            case 'ArrowDown':
                setSelected((selected + 1) % options.length);
                e.preventDefault();
                break;
            case 'ArrowUp':
                setSelected(selected <= 0 ? options.length - 1 : selected - 1);
                e.preventDefault();
                break;
            case 'Enter':
                selectCurrentOption();
                e.preventDefault();
                break;
            case 'Tab':
                selectCurrentOption();
                break;
            case 'Escape':
                setOptions([]);
                setSelected(-1);
                setNoResults(false);
                break;
        }
    };

    const onFocus = (event): void => {
        event.target.select();
        setFocus(true);
    };

    const onBlur = (): void => {
        setFocus(false);
        selectCurrentOption();
    };

    const onClear = (): void => {
        setText('');
        setSelected(-1);
        setOptions([]);
        setNoResults(false);
        onSelect(null);
    };

    const onInputChange = ({ target: { value } }): void => {
        if (value.length >= minChars) {
            setText(value);
            debouncedFetchOptions(value);
        } else if (value.length >= 1) {
            setText(value);
        } else {
            onClear();
        }
    };

    const renderOptions = () =>
        focus && (options.length || noResults) ? (
            <ul
                id={`${id}-options`}
                data-testid={`${id}-options`}
                className="absolute bg-white left-0 right-0 shadow"
                style={{ zIndex: 2 }}>
                {options.length
                    ? options.map((option, index) => (
                          <li
                              // biome-ignore lint/suspicious/noArrayIndexKey: This was set before biome was added
                              key={`${id}-option-${index}`}
                              id={`${id}-option-${index}`}
                              data-testid={`${id}-option-${index}`}
                              className={classNames('p-8 truncate cursor-pointer text-14', option.cls, {
                                  'bg-green-100 font-bold': selected === index,
                                  'flex justify-between gap-x-8': (option.count ?? 0) > 0,
                              })}
                              onClick={() => selectOption(option)}
                              onMouseOver={() => setSelected(index)}
                              aria-selected={selected === index}>
                              {option.type ? <div className="text-12 text-gray-400">{option.type}</div> : null}
                              <span className="truncate">{option.text}</span>
                              {(option.count ?? 0) > 0 ? <span className="text-14">{option.count}</span> : null}
                          </li>
                      ))
                    : null}
                {noResults ? <li className="text-gray-500 p-8">Fant ingen resultater...</li> : null}
            </ul>
        ) : null;

    const hasSelectedOption = options.length > 0 && selected !== -1;

    return (
        <div className={`relative w-full ${className ?? ''}`}>
            <TextField
                type="text"
                id={id}
                data-testid={id}
                value={text}
                label={label}
                placeholder={placeholder ?? undefined}
                onKeyDown={onKeyDown}
                onChange={onInputChange}
                onFocus={onFocus}
                onBlur={onBlur}
                role="combobox"
                aria-autocomplete="list"
                aria-activedescendant={hasSelectedOption && selected >= 0 ? `${id}-option-${selected}` : undefined}
                aria-controls={hasSelectedOption ? `${id}-options` : undefined}
                aria-expanded={hasSelectedOption}
                invalid={!!error}
                helpText={error}
                className={fetching ? 'spinner autocomplete-spinner' : undefined}>
                {text?.length > 0 ? <Affix suffix clear onClick={onClear} aria-label="Nullstill" /> : null}
            </TextField>
            {renderOptions()}
        </div>
    );
};
