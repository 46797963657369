import { type FormEvent, useState } from 'react';
import getConfig from 'next/config';
import { DatePickerCalendar, DatePickerInput, DatePickerPopover, DateRangePicker } from '@finn-no/legacy-fabric-datepicker';
import { TextField } from '@fabric-ds/react';
import type { ReduxAction } from '@finn-no/redux-actions';

import { t } from '../../locale/texts';
import { isMobile } from '../../util/mobile';
import { differenceInCalendarDays, isNotWithinOneYearOfToday } from '../../util/datetime-fns';
import { getOneYearFromToday } from '../../util/calendar';

import { hotelAutocompleteMapper } from '../common/autocompleteMapper';
import { storeLastSearchHotel } from '../common/lastSearch';
import { trackSearchClick } from '../common/tracking';

import actions from './hotelActions';

import { Autocomplete } from '../common/Autocomplete';
import { Dropdown } from '../common/Dropdown';
import { type HotelRoom, HotelRooms } from './HotelRooms';
import { HotelSearchSubmit } from './HotelSearchSubmit';
import { FrontpageDatePickerWrapper } from '../../components/frontpageDatePickerWrapper/FrontpageDatePickerWrapper';

export type HotelAutocompleteOption = {
    text: string;
    key?: string;
    hierarchy: string[];
    searchable: boolean;
    type: 'hotel' | 'place' | 'country' | 'theme';
    themePath?: string;
};

export type HotelSearchFormProps = {
    dispatch: (action: ReduxAction) => void;
    destination?: string;
    destinationContext?: HotelAutocompleteOption;
    startDate?: Date;
    endDate?: Date;
    rooms?: HotelRoom[];
    tracking?: string;
    widget?: string;
    position: string;
    unleash?: Record<string, boolean>;
};

const {
    publicRuntimeConfig: { hotelAutocompleteUrl, hotelResultUrl },
} = getConfig();

export const HotelSearchForm = (props: HotelSearchFormProps) => {
    const [pristine, setPristine] = useState(true);

    const generateRoomInfoText = (): string => {
        const roomsText = t('hotel.room.amount', { count: props.rooms?.length });
        const adultsText = t('flight.passengers.adult', { count: props.rooms?.reduce((last, current) => last + current.adults, 0) });
        const childrenCount = props.rooms?.reduce((last, current) => last + current.children.length, 0);

        if (childrenCount && childrenCount > 0) {
            const childrenText = t('flight.passengers.child', { count: childrenCount });
            return t('hotel.room.infoAdultsChildren', { adultsText, childrenText, roomsText });
        }
        return t('hotel.room.infoAdults', { adultsText, roomsText });
    };

    const roomsAreValid = (): boolean => !props.rooms?.some((room) => room.children.length + room.adults === 0);
    const datesAreOk = (): boolean =>
        !!props.startDate && !!props.endDate && differenceInCalendarDays(props.startDate, props.endDate) <= 30;
    const datesAreClear = (): boolean => !props.startDate && !props.endDate;
    const datesAreValid = (): boolean => datesAreOk() || (!props.unleash?.kayakWhitelabel && datesAreClear());
    const needsMoreInput = (): boolean => !datesAreValid() || !roomsAreValid() || !props.destination || !props.destinationContext;

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        setPristine(false);

        if (needsMoreInput()) {
            event.preventDefault();
        } else {
            const destination = props.destinationContext;
            const numberOfAdults = props.rooms?.reduce((sum, room) => sum + room.adults, 0);
            const numberOfChildren = props.rooms?.reduce((sum, room) => sum + room.children.length, 0);

            const item = {
                id: 'finntravel',
                type: 'Travel',
                destination: destination ? destination.hierarchy[0] : undefined,
                arrivalCity: destination ? destination.hierarchy[1] : undefined,
                carriers: destination?.type === 'hotel' ? [destination.key] : undefined,
                travelType: 'hotel',
                departureDate: props.startDate?.toISOString(),
                returnDate: props.endDate?.toISOString(),
                numberOfAdults: numberOfAdults,
                numberOfChildren: numberOfChildren,
                numberOfRooms: props.rooms?.length,
            };
            trackSearchClick(
                'Search hotel',
                '/reise/hotell',
                'hotel',
                props.unleash?.kayakWhitelabel ? `${props.position} - whitelabel` : props.position,
                item,
            );
            storeLastSearchHotel(props);
        }
    };

    const numberOfMonths = isMobile() ? 1 : 2;

    const invalidDate = (thisDate: Date | undefined, otherDate: Date | undefined) =>
        (!thisDate && otherDate) || (props.unleash?.kayakWhitelabel && !thisDate);

    const invalid = {
        destination: !pristine && (!props.destination || !props.destinationContext),
        rooms: !pristine && !roomsAreValid(),
        startDate: !pristine && invalidDate(props.startDate, props.endDate),
        endDate: !pristine && invalidDate(props.endDate, props.startDate),
        dateRange: !pristine && props.startDate && props.endDate && differenceInCalendarDays(props.startDate, props.endDate) > 30,
    };

    return (
        <section className="bg-aqua-50 rounded-8 p-16" data-testid="hotelSearch" aria-label={t('hotel.title')}>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-16">
                <Autocomplete
                    id="destination"
                    data-testid="hotelAutocomplete"
                    label={t('hotel.destination.label')}
                    placeholder={t('hotel.destination.placeholder')}
                    defaultValue={props.destination}
                    onSelect={(destination) => props.dispatch(actions.setDestination(destination))}
                    url={hotelAutocompleteUrl}
                    error={invalid.destination ? t('hotel.validation.destination') : undefined}
                    mapOptions={hotelAutocompleteMapper}
                />
                {props.unleash?.basicDatePicker ? (
                    <FrontpageDatePickerWrapper
                        useRange
                        upperDateLimit={getOneYearFromToday()}
                        startDate={props.startDate}
                        endDate={props.endDate}
                        onDateChange={({ startDate, endDate }) => {
                            props.dispatch(actions.setDates({ startDate, endDate }));
                        }}>
                        <TextField
                            id="date-range-start"
                            data-testid="Hotel-dateRangeStart"
                            label={t('hotel.checkin.label')}
                            invalid={!!invalid.startDate}
                            helpText={invalid.startDate && t('hotel.validation.dateRangeStart')}
                        />
                        <TextField
                            id="date-range-end"
                            data-testid="Hotel-dateRangeEnd"
                            label={t('hotel.checkout.label')}
                            invalid={!!invalid.endDate || !!invalid.dateRange}
                            helpText={
                                invalid.endDate
                                    ? t('hotel.validation.dateRangeEnd')
                                    : invalid.dateRange
                                      ? t('hotel.validation.dateRangeGap')
                                      : undefined
                            }
                        />
                    </FrontpageDatePickerWrapper>
                ) : (
                    <DateRangePicker
                        startDate={props.startDate}
                        endDate={props.endDate}
                        minimumNights={1}
                        onChange={(newDates) => props.dispatch(actions.setDates(newDates))}
                        isDayDisabled={isNotWithinOneYearOfToday}>
                        <div className="grid grid-cols-2 gap-x-16">
                            {/* @ts-ignore - complains on the error attribute, for some reason */}
                            <DatePickerInput
                                as={TextField}
                                dateField="startDate"
                                data-testid="Hotel-dateRangeStart"
                                id="date-range-start"
                                label={t('hotel.checkin.label')}
                                aria-label={t('hotel.checkin.label')}
                                placeholder={t('hotel.checkin.placeholder')}
                                error={!!invalid.startDate}
                                helpText={invalid.startDate ? t('hotel.validation.dateRangeStart') : undefined}
                            />
                            {/* @ts-ignore - complains on the error attribute, for some reason */}
                            <DatePickerInput
                                as={TextField}
                                dateField="endDate"
                                data-testid="Hotel-dateRangeEnd"
                                id="date-range-end"
                                label={t('hotel.checkout.label')}
                                aria-label={t('hotel.checkout.label')}
                                placeholder={t('hotel.checkout.placeholder')}
                                error={!!invalid.endDate || !!invalid.dateRange}
                                helpText={
                                    invalid.endDate
                                        ? t('hotel.validation.dateRangeEnd')
                                        : invalid.dateRange
                                          ? t('hotel.validation.dateRangeGap')
                                          : undefined
                                }
                            />
                        </div>
                        {/* TS2590: Expression produces a union type that is too complex to represent.
                        we have no power here, so just ignoring it :(
                        @ts-ignore expect-error does not work properly for this error for some reason...*/}
                        <DatePickerPopover className="date-popover">
                            <DatePickerCalendar numberOfMonths={numberOfMonths} />
                        </DatePickerPopover>
                    </DateRangePicker>
                )}

                <Dropdown id="hotelRooms" label={t('hotel.room.title')} text={generateRoomInfoText()}>
                    <div aria-live="polite" className="sr-only">
                        {t('hotel.room.amount', { count: props.rooms?.length })}
                    </div>
                    <HotelRooms rooms={props.rooms ?? []} dispatch={props.dispatch} />
                </Dropdown>
            </div>
            <HotelSearchSubmit {...props} handleSubmit={handleSubmit} resultUrl={hotelResultUrl} />
        </section>
    );
};
