// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
const empty = (e: any): boolean => {
    if (typeof e === 'undefined') {
        return true;
    }
    if (typeof e === 'string') {
        // biome-ignore lint/style/noParameterAssign: This was set before biome was added
        e = e.trim();
    }

    switch (e) {
        case '':
        case 0:
        case '0':
        case null:
        case false:
            return true;
        default:
            return false;
    }
};

// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
const notEmpty = (e: any): boolean => !empty(e);

export const validateSearchForm = (data): boolean => {
    const { origin, destination, origin2, destination2, adults, departureDate, returnDate, tripType, cabinType } = data;

    const invalidOpenJaw = () => tripType === 'openjaw' && (empty(origin2) || empty(destination2));
    const invalidReturnDate = () => tripType !== 'oneway' && empty(returnDate);
    const sameDestination = () => origin === destination;
    const sameDestinationOpenJaw = () => tripType === 'openjaw' && origin2 === destination2;
    const invalidReturnDateOrder = tripType === 'openjaw' && new Date(departureDate) >= new Date(returnDate);

    return (
        notEmpty(origin) &&
        notEmpty(destination) &&
        notEmpty(adults) &&
        notEmpty(departureDate) &&
        notEmpty(cabinType) &&
        !invalidReturnDate() &&
        !invalidOpenJaw() &&
        !sameDestination() &&
        !sameDestinationOpenJaw() &&
        !invalidReturnDateOrder
    );
};
