import { Button } from '@fabric-ds/react';
import styles from './FrontpageBanner.module.css';

const bannerContent = {
    header: 'Klar for en forsmak på våren?',
    text: 'Nå kan du finne reiser til hele Europa! Book tur til din favoritt storby på 1 - 2 - 3!',
    callToAction: 'Bli inspirert her',
    link: 'https://www.finn.no/reise/artikler/storbyferie',
    img: {
        src: 'https://finn-content-hub.imgix.net/bilder/reise/2024-GettyImages-1508305542.jpg?auto=compress&crop=focalpoint&domain=finn-content-hub.imgix.net&fit=crop&fm=jpg&fp-x=0.5&fp-y=0.5&h=300&w=475',
        alt: 'En eldre bygård med Eiffeltårnet i bakgrunnen.',
    },
};

export const FrontpageBanner = () => {
    const { header, text, callToAction, link, img } = bannerContent;

    return (
        <section className="flex flex-wrap flex-auto shadow-2 rounded-16 items-center justify-center md:flex-row-reverse md:flex-nowrap md:justify-between my-32">
            <div className={`w-full h-full overflow-hidden lg:rounded-lg ${styles.imgWrapper}`}>
                <img
                    className={`flex justify-center items-center text-center object-cover object-left min-w-full ${styles.img}`}
                    src={img.src}
                    alt={img.alt}
                />
            </div>
            <div className="p-32 lg:p-40 gap-y-16 lg:gap-y-20 text-center md:text-left container flex flex-col flex-auto justify-center items-center md:items-start">
                <div className="flex flex-col gap-y-8 md:gap-y-10">
                    <h2>{header}</h2>
                    <p>{text}</p>
                </div>
                <Button primary href={link} className="px-28 md:px-40">
                    {callToAction}
                </Button>
            </div>
        </section>
    );
};
