import { useCallback } from 'react';
import { formatDate, resetHours } from 'shared/util/calendar';

interface UpdateSingleDateProps {
    startDate: Date | null;
    setStartDate: (date: Date | null) => void;
    setInputStartDate: (dateString: string) => void;
    setIsStartDateFocused: (flag: boolean) => void;
}

export const useUpdateSingleDate = ({ startDate, setStartDate, setInputStartDate, setIsStartDateFocused }: UpdateSingleDateProps) => {
    return useCallback(
        (date: Date) => {
            if (date === null) {
                setStartDate(null);
                setInputStartDate('');
                setIsStartDateFocused(true);
                return;
            }

            const resetDate = resetHours(new Date(date));
            const normalizedDate = new Date(resetDate ?? date);

            if (startDate && normalizedDate.getTime() === startDate.getTime()) {
                setStartDate(null);
                setInputStartDate('');
                setIsStartDateFocused(false);
                return;
            }

            setStartDate(normalizedDate);
            setInputStartDate(formatDate(normalizedDate));
            setIsStartDateFocused(true);
        },
        [startDate, setStartDate, setInputStartDate, setIsStartDateFocused],
    );
};
