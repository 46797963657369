import { t } from '../../locale/texts';
import type { AutocompleteOption, AutocompleteResponse } from './Autocomplete';

export const flightAutocompleteMapper = (res: AutocompleteResponse): AutocompleteOption[] =>
    res.map((opt) => ({
        id: opt.value,
        text: opt.text,
        cls: opt.indent === 'true' ? 'pl-24' : undefined,
        city: opt.cityName,
        country: opt.countryName,
    }));

export const fhhAutocompleteMapper = (res: AutocompleteResponse): AutocompleteOption[] =>
    res
        .filter((opt) => opt.primary)
        .map((opt) => ({
            id: opt.primary + opt.secondary,
            text: opt.destination,
            count: +opt.numResults,
            bounds: opt.bounds,
            city: opt.city,
            country: opt.country,
        }));

export const carAutocompleteMapper = (res: AutocompleteResponse): AutocompleteOption[] =>
    res.map((opt, idx: number) => ({
        id: opt.code || idx.toString(),
        text: opt.name,
        type: opt.type === '1' ? t('car.autocomplete.airport') : t('car.autocomplete.area'),
        typeLogo: opt.type === '8' ? 'assets/img/powered-by-google-white.png' : null,
        code: opt.code,
        placeId: opt.placeId,
        latitude: opt.latitude,
        longitude: opt.longitude,
    }));

export const hotelAutocompleteMapper = (res: AutocompleteResponse): AutocompleteOption[] =>
    res.map((opt) => ({
        id: `${opt?.type === 'hotel' ? 'hotel' : 'place'}:${opt?.key}`,
        text: opt.text,
        ...opt,
    }));

export const ppAutocompleteMapper = (res: AutocompleteResponse): AutocompleteOption[] => res.map((opt) => ({ id: opt.id, text: opt.name }));
